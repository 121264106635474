import Vue from 'vue';
import App from './App.vue';
import store from './store';

import './assets/css/iconfont.css'

// import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//
// Vue.use(ElementUI);
import { Button, Select,Option,Input,Pagination,Dialog,Dropdown,DropdownMenu,DropdownItem,Table,TableColumn,Form,FormItem,Menu,Submenu,MenuItem,Row,Col,DatePicker,Divider,Link,PageHeader,Tabs,TabPane,Radio,RadioGroup,Card,Collapse,CollapseItem,Popover,Tag,Checkbox,CheckboxGroup,Cascader,Upload,Slider,Image,Autocomplete,Descriptions,DescriptionsItem,Loading,Message,MessageBox} from 'element-ui';
Vue.use(Button).use(Select).use(Option).use(Input).use(Pagination).use(Dialog).use(Dropdown).use(DropdownMenu).use(DropdownItem).use(Table).use(TableColumn).use(Form).use(FormItem).use(Menu).use(Submenu).use(MenuItem).use(Row).use(Col).use(DatePicker).use(Divider).use(Link).use(PageHeader).use(Tabs).use(TabPane).use(Radio).use(RadioGroup).use(Card).use(Collapse).use(CollapseItem).use(Popover).use(Tag).use(Checkbox).use(CheckboxGroup).use(Cascader).use(Upload).use(Slider).use(Image).use(Autocomplete).use(Descriptions).use(DescriptionsItem).use(Loading.directive);

Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;

Vue.config.productionTip = false

import router from './router';

import {post, get, download} from '@/utils/http'
//定义全局变量
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$download = download;

import api from '@/utils/api';
Vue.prototype.$api = api;


(function () {
    //当前页面没有sessionStorage：开启了一个新的tab页时触发
    if (!sessionStorage.length) {
        localStorage.setItem('getSessionStorage', Date.now());
    }
    //监听storage事件
    window.addEventListener('storage', function(event) {
        //1.目的：触发原页面再localStorage存一下sessionStorage，向其他的tab页面发送一个信号，触发下面那个key=sessionStorage的分支
        //2.解释：在原页面就会触发事件，将sessionStorage存入localStorage，为了触发storage监听，然后清除localStorage，不留痕迹，就时一瞬间的事
        if (event.key == 'getSessionStorage') {
            //console.log(sessionStorage);
            localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
            localStorage.removeItem('sessionStorage')
        } else {
            //1.目的：在tabs页面中通过上面的触发，接受sessionStorage参数
            //2.解释：storage监听到key=sessionStorage的取出存入的sessionStorage，循环存入新的标签
            if (event.key == 'sessionStorage' && !sessionStorage.length) {
                let data = JSON.parse(event.newValue);
                //console.log('data', data);
                for (let key in data) {
                    sessionStorage.setItem(key, data[key]);
                }
            }
        }
    });
})();


new Vue({
  render: h => h(App),
    router,
    store,
}).$mount('#app')
