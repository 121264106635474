import Vue from 'vue'
import vuex from 'vuex'
Vue.use(vuex);

export default new vuex.Store({
    state: {
        isCollapse: false,
        left: 'left:160px',
        selReceiver: false,
        selImsi: {},
        selDevice: {},
        selReceiverList: []
    },
    // getters: {
    //     getSelDevice: state => {
    //         return state.selDevice;
    //     }
    // },
    mutations: {
        switch_menu(state) {
            state.isCollapse = state.isCollapse ? false : true;
            state.left = state.left == 'left:160px' ? 'left:50px' : 'left:160px';
        },
        selImsi(state, val) {
            state.selImsi = val;
        },
        selDevice(state, val) {
            console.log(val);
            state.selDevice = val;
        },
        selReceiverList(state, val) {
            state.selReceiverList = val;
        }
    }
})
