import Vue from 'vue'
import Router from 'vue-router'
//解决vue路由重复导航错误
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/main'
    },
    {
      path: '/test',
      name: 'test',
      component: resolve => require(['@/views/test'], resolve)
    },
    {
      path: '/login',
      name: 'Login',
      component: resolve => require(['@/views/Login'], resolve)
    },
    {
      path: '/main',
      component: resolve => require(['@/components/common/Home'], resolve),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/',
          name: 'main',
          component: resolve => require(['@/views/Main'], resolve)
        },
        {
          path: '/device',
          name: 'device',
          component: resolve => require(['@/views/Device'], resolve)
        },
        {
          path: '/device/detail',
          name: 'devicedetail',
          component: resolve => require(['@/views/DeviceDetail'], resolve)
        },
        {
          path: '/app',
          name: 'app',
          component: resolve => require(['@/views/App'], resolve)
        },
        {
          path: '/app/detail',
          name: 'appdetail',
          component: resolve => require(['@/views/AppDetail'], resolve)
        },
        {
          path: '/soft',
          name: 'soft',
          component: resolve => require(['@/views/Soft'], resolve)
        },
        {
          path: '/soft/type',
          name: 'softtype',
          component: resolve => require(['@/views/SoftType'], resolve)
        },
        {
          path: '/soft/detail',
          name: 'softdetail',
          component: resolve => require(['@/views/SoftDetail'], resolve)
        },
        {
          path: '/group',
          name: 'group',
          component: resolve => require(['@/views/Group'], resolve)
        },
        {
          path: '/group/detail',
          name: 'groupdetail',
          component: resolve => require(['@/views/GroupDetail'], resolve)
        },
        {
          path: '/network',
          name: 'network',
          component: resolve => require(['@/views/Network'], resolve)
        },
        {
          path: '/network/detail',
          name: 'networkdetail',
          component: resolve => require(['@/views/NetworkDetail'], resolve)
        },
        {
          path: '/other',
          name: 'other',
          component: resolve => require(['@/views/Other'], resolve)
        },
        {
          path: '/other/detail',
          name: 'otherdetail',
          component: resolve => require(['@/views/OtherDetail'], resolve)
        },
        {
          path: '/room',
          name: 'room',
          component: resolve => require(['@/views/Room'], resolve)
        },
        {
          path: '/manager',
          name: 'manager',
          component: resolve => require(['@/views/Manager'], resolve)
        },
        {
          path: '/report',
          name: 'report',
          component: resolve => require(['@/views/Report'], resolve)
        },
        {
          path: '/safe',
          name: 'safe',
          component: resolve => require(['@/views/Safe'], resolve)
        },
        {
          path: '/safe/detail',
          name: 'safedetail',
          component: resolve => require(['@/views/SafeDetail'], resolve)
        },
        {
          path: '/admin',
          name: 'admin',
          component: resolve => require(['@/views/Admin'], resolve)
        },
        {
          path: '/log',
          name: 'log',
          component: resolve => require(['@/views/Log'], resolve)
        },
      ]
    }
  ]
});
