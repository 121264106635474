const api = {
  //公共模块
  BaseUrl: process.env.NODE_ENV == 'production' ? '' : process.env.VUE_APP_API_URL,
  Login: '/login', //登录
  Logout: '/logout',  //退出
  //GetAuthQrcode: '/login/getAuthQrcode', //获取Auth二维码图片 google地址访问不到
  AuthValidate: '/authValidate', //二次认证登录
  EditPassword: '/editPassword', //修改密码
  GetUserInfo: '/getUserInfo', //获取用户信息
  UploadFile: '/uploadFile', //上传图片文件
  UploadDeviceImage: '/uploadDeviceFile', //上传设备文件

  //首页
  GetTotalInfo: 'main/getTotals', //各分类设备数量统计
  GetFirewallCover: 'main/getFirewallCover', //防火墙覆盖统计
  GetChart: 'main/chart', //获取图表数据
  // GetAppTreeChart: 'main/getAppTreeChart', //获取应用软件拓扑图
  // GetSafeTreeChart: 'main/getSafeTreeChart', //获取安全设备树状图数据
  // GetOtherTreeChart: 'main/getOtherTreeChart', //获取其他设备树状图数据
  // GetGroupGraphChart: 'main/getGroupGraphChart', //获取集群拓扑图

  //设备
  Device: 'device/',
  GetDeviceList: 'device/getList', //获取设备列表
  GetDeviceSimpleList: 'device/getSimpleList', //获取设备简单列表
  GetDeviceInfo: 'device/info', //获取设备详情
  AddDevice: 'device/add', //新增设备
  EditDevice: 'device/edit', //修改设备
  UpdateDevice: 'device/update', //修改设备单独信息字段
  DeleteDevice: 'device/delete', //删除设备
  GetTagList: 'device/getTagList', //获取标签列表
  GetDeviceGraphChart: 'device/graphChart', //主机关系图谱
  GetDeviceChart: 'device/chart', //获取单个设备关系图谱
  GetDevAppList: 'device/getAppList', //获取单个设备的应用列表
  GetDevSoftList: 'device/getSoftList', //获取单个主机的单独软件列表
  GetSafeOptions: 'device/getOptions', //获取安全选项
  GetDevicePorts: 'device/getPorts', //获取主机端口覆盖信息
  ImportDeviceExcel: 'device/import', //导入设备
  ExportDeviceExcel: 'device/export', //导出设备
  GetTypeList: 'device/getTypeList', //获取设备类型列表
  GetDeviceOption: 'device/getDeviceOption', //获取设备列表（主机、安全、其他设备）
  GetDeviceTotal: 'device/getTotals', //获取设备统计信息
  GetDeviceChartKeys: 'device/chartDevice', //通过搜索关键字获取设备完整的关系图
  GetOsList: 'device/getOsList', //获取设备操作系统类型

  //设备接口
  GetInterfaceList: 'interfaces/getList', //获取设备接口列表
  GetInterfaceInfo: 'interfaces/info', //获取设备接口详情
  AddInterface: 'interfaces/add', //增加设备接口
  EditInterface: 'interfaces/edit', //修改设备接口
  DeleteInterface: 'interfaces/delete', //删除设备接口

  //机房
  GetRoomList: 'room/getList', //获取机房列表
  GetRoomInfo: 'room/info', //获取机房详情
  AddRoom: 'room/add', //新增机房
  EditRoom: 'room/edit', //修改机房
  DeleteRoom: 'room/delete', //删除机房
  GetRoomOption: 'room/getRoomOption', //获取机房机柜位置下拉选项
  ImportRoomExcel: 'room/import', //导入机柜
  ExportRoomExcel: 'room/export', //导出机柜
  GetNearRoom: 'room/getNear', //获取附近的机房
  //机柜
  // GetCabList: 'cab/getList', //获取机柜列表
  // GetCabInfo: 'cab/info', //获取机柜详情
  // AddCab: 'cab/add', //新增机柜
  // EditCab: 'cab/edit', //修改机柜
  // DeleteCab: 'cab/delete', //删除机柜

  //设备主机与应用对应关系
  AddDevSoft: 'devsoftmap/add', //新增主机与应用对应关系
  EditDevSoft: 'devsoftmap/edit', //修改主机与应用对应关系
  DeleteDevSoft: 'devsoftmap/delete', //删除主机与应用对应关系
  GetDevOfSoft: 'devsoftmap/getDevList', //获取应用软件关联主机列表
  GetSoftOfDev: 'devsoftmap/getSoftList', //获取主机关联de应用软件列表
  GetDevSoftInfo: 'devsoftmap/info', //获取主机与应用对应关系详情

  //安全设备
  GetSafeList: 'safedevice/getList', //获取安全设备列表
  AddSafeDevice: 'safedevice/add', //新增安全设备
  EditSafeDevice: 'safedevice/edit', //修改安全设备
  GetSafeDeviceInfo: 'safedevice/info', //获取安全设备详情
  DeleteSafeDevice: 'safedevice/delete', //删除安全设备
  GetSafeSimpleList: 'safedevice/getSimpleList', //获取安全设备简单列表
  GetSafeChart: 'safedevice/chart', //获取单个安全设备关系图谱
  ImportSafeExcel: 'safedevice/import', //导入安全设备
  ExportSafeExcel: 'safedevice/export', //导出安全设备
  GetDeviceTypelist: 'type/getList', //获取设备类别列表

  //其他设备
  GetOtherList: 'other/getList', //获取其他设备列表
  GetOtherTypeList: 'other/getTypeList', //获取其他设备类型列表
  AddOther: 'other/add', //新增其他设备
  EditOther: 'other/edit', //修改其他设备
  GetOtherInfo: 'other/info', //获取其他设备详情
  DeleteOther: 'other/delete', //删除其他设备
  GetOtherSimpleList: 'other/getSimpleList', //获取其他设备简单列表
  GetOtherChart: 'other/chart', //获取单个其他设备关系图谱
  ImportOtherExcel: 'other/import', //导入其他设备
  ExportOtherExcel: 'other/export', //导出其他设备

  GetDevMapList: 'devicemap/getList', //获取关联设备列表
  GetDevMapInfo: 'devicemap/info', //获取关联设备详情
  GetSafeDevMapInfo: 'devicemap/infoSafe', //获取主机关联安全设备详情
  AddDevMap: 'devicemap/add', //新增设备关联的设备
  EditDevMap: 'devicemap/edit', //修改设备关联的设备
  DeleteDevMap: 'devicemap/delete', //删除关联的设备

  //关联设备
  AddDevLink: 'devicelink/add', //新增设备关联的设备
  EditDevLink: 'devicelink/edit', //修改设备关联的设备
  DeleteDevLink: 'devicelink/delete', //删除关联的设备

  //管理者
  GetManagerList: 'manager/getList', //获取管理者列表
  GetManagerInfo: 'manager/info', //获取管理者详情
  AddManager: 'manager/add', //新增管理者
  EditManager: 'manager/edit', //修改管理者
  DeleteManager: 'manager/delete', //删除管理者

  //应用
  GetAppList: 'app/getList', //获取应用列表
  GetAppInfo: 'app/info', //获取应用详情
  AddApp: 'app/add', //新增应用
  EditApp: 'app/edit', //修改应用
  DeleteApp: 'app/delete', //删除应用
  GetDevappMapList: 'app/getDevList', //获取设备与应用关联关系列表
  GetDevOption: 'app/getDevOption', //获取设备简单选项列表
  AddDevappMap: 'app/addDeviceMap', //新增应用和设备对应关系
  EditDevappMap: 'app/editDeviceMap', //修改应用和设备对应关系
  DeleteDevappMap: 'app/deleteDeviceMap', //删除应用和设备对应关系
  GetDevappMapInfo: 'app/mapinfo', //获取应用关联设备详情
  GetAppChart: 'app/getAppChart', //获取应用树状结构图

  //软件
  GetSoftList: 'soft/getList', //获取应用列表
  GetSoftSimpleList: 'soft/getSimpleList', //获取应用简单列表
  GetSoftInfo: 'soft/info', //获取应用详情
  AddSoft: 'soft/add', //新增应用
  EditSoft: 'soft/edit', //修改应用
  UpdateSoft: 'soft/update', //修改应用单个字段
  DeleteSoft: 'soft/delete', //删除应用
  GetAppTypelist: 'soft/getTypeList', //获取应用类型列表
  GetSoftMapList: 'softmap/getList', //应用与应用直接关系
  AddSoftMap: 'softmap/add', //新增应用与应用关系
  DeleteSoftMap: 'softmap/delete', //删除应用与应用关系
  GetSoftChart: 'soft/chart', //应用关系图谱
  ImportSoftExcel: 'soft/import', //导入应用
  ExportSoftExcel: 'soft/export', //导出应用
  GetSoftTypeList: 'soft/type/list', //获取软件类别列表
  AddSoftType: 'soft/type/add', //新增软件类别
  EditSoftType: 'soft/type/edit', //修改软件类别
  DeleteSoftType: 'soft/type/delete', //删除软件类别
  GetSoftTypeInfo: 'soft/type/info', //获取软件类别详情

  //集群
  GetGroupList: 'group/getList', //获取集群列表
  GetGroupInfo: 'group/info', //获取集群详情
  AddGroup: 'group/add', //新增集群
  EditGroup: 'group/edit', //修改集群
  DeleteGroup: 'group/delete', //删除集群
  GetGroupChart: 'group/chart', //集群关系图谱
  GetGroupDeviceList: 'group/getDeviceList', //获取集群关联的主机
  GetDevSoftOption: 'group/getDevSoftList', //获取主机上关联的应用

  //容灾高可用
  GetHaList: 'ha/getList',//获取容灾列表
  SetHa: 'ha/setHa', //设置关联容灾信息
  DeleteHa: 'ha/delete', //删除容灾
  GetHaInfo: 'ha/info', //获取容灾详情
  GetHostHaInfo: 'ha/getHostHaInfo', //获取主机容灾信息

  //数据分析报表
  GetReportDevChart: 'report/getDeviceChart', //获取设备分布图表
  GetReportAppChart: 'report/getAppChart', //获取应用分布图表
  GetReportSafeChart: 'report/safeChart', //获取设备安全覆盖度
  GetSafeCoverList: 'report/safeRefList', //获取安全类别下覆盖的设备列表
  GetReportFirewallChart: 'report/getFirewallChart', //获取防火墙覆盖度
  GetReportVirChart: 'report/getVirChart', //获取杀毒防护覆盖度
  GetReportIntrudeChart: 'report/getIntrudeChart', //获取入侵检测覆盖度
  GetReportDefensChart: 'report/getDefensChart', //获取主机防护覆盖度
  GetReportSoftChart: 'report/getReportSoftChart', //获取主机关联指定类型应用覆盖度
  GetReportHostAccoutSafe: 'report/getHostAccoutSafe',//获取主机账号安全信息
  GetReportHostLoginSafe: 'report/getHostLoginSafe',//获取主机账号安全信息
  GetReportHostPortSafe: 'report/getHostPortSafe',//获取主机端口开放雷达数据
  GetReportPasswordChart: 'report/getHostPasswordSafe',//获取主机软件弱密码雷达数据
  GetSafePortList: 'report/getPortlist', //获取端口列表
  GetSafeArea: 'report/getSafeArea', //获取安全设备覆盖的主机数据
  GetSoftHostArea: 'report/getSoftHostArea', //获取关联应用软件覆盖的主机设备
  GetPopTablist: 'report/getPopTablist', //获取弹出层标签列表
  GetPopTagData: 'report/getPopTagData', //获取标签对应的主机设备列表
  GetReportSoftRefChart: 'report/getReportSoftRefChart', //获取应用软件关联主机
  GetReportSafedataChart: 'report/getReportSafedataChart', //获取应用数据加密关联的应用和主机
  GetReportDevicePortList: 'report/getPortsList', //获取主机端口覆盖信息列表

  //管理员
  GetAdminList: 'admin/getList', //获取管理员列表
  GetAdminInfo: 'admin/info', //获取管理员详情
  AddAdmin: 'admin/add', //新增管理员
  EditAdmin: 'admin/edit', //修改管理员
  DeleteAdmin: 'admin/delete', //删除管理员
  ResetAdminPass: 'admin/resetPass', //重置管理员密码

  //日志
  GetLogList: 'log/getList', //获取日志列表

}

export default api;
