import axios from 'axios';
import { Message } from 'element-ui';
import router from '../router';
//import service from "./request";

axios.defaults.timeout = 30000; //30s超时
axios.defaults.baseURL = process.env.NODE_ENV == 'production' ? '' : process.env.VUE_APP_API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

axios.defaults.withCredentials = true;//让ajax携带cookie

//http request 拦截器
axios.interceptors.request.use(
    config => {
        let token = sessionStorage.getItem('access-token')
        if (token) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers = {
                'access-token': token
            }
        }

        return config
    },
    err => {
        return Promise.reject(err)
    }
)
// 拦截响应
axios.interceptors.response.use(
    response => {
      // 未设置状态码则默认成功状态
      const code = response.data.code || 200;
      let message = response.data.message;
      if (message == '' || message === null || message === undefined) {
        message = '未知错误，请联系管理员';
      }
      if (code === 103 || code === 401) {
        Message({
          message: message,
          type: 'error'
        });
        window.sessionStorage.removeItem('access-token');
        //location.href = '/login';
        router.push({
          path:"/login",
          querry:{redirect: router.currentRoute.fullPath}//从哪个页面跳转
        })
      } else if (code === 500) {
        Message({
          message: message,
          type: 'error'
        })
        return Promise.reject(new Error(message))
      } else if (code === 200 || code === 0) {
        return response;
      } else {
        Message({
          message: message,
          type: 'error'
        });
        //return response;
        return Promise.reject(new Error(message));
      }
    },
    error => {
      console.log(error)
      let { message } = error;
      if (message == "Network Error") {
        message = "后端接口连接异常";
      } else if (message.includes("timeout")) {
        message = "系统接口请求超时";
      } else if (message.includes("Request failed with status code")) {
        message = "系统接口" + message.substr(message.length - 3) + "异常";
      }
      Message({
        message: message,
        type: 'error',
        duration: 5 * 1000
      });
      return Promise.reject(error);
    }
);

// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         let token = sessionStorage.getItem('access-token');
//         console.log(token);
//         //next()
//         if (!token) {
//             Message.warning('请先登录！')
//             next({
//                 path: 'login',
//                 query: {
//                     redirect: to.fullPath
//                 }
//             })
//             next()
//         } else {
//             next()
//         }
//     } else {
//         next()
//     }
// })


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params={}){
    return new Promise((resolve, reject) => {
        axios.get(url,{
            params:params
        })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}){
    return new Promise((resolve, reject) => {
        axios.post(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}){
    return new Promise((resolve, reject) => {
        axios.patch(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}){
    return new Promise((resolve, reject) => {
        axios.put(url,data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装download http方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function download(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: url,
            params: params,
            responseType: 'blob'
        })
            .then((response) => {
                if (response) {
                    resolve(response);
                } else {
                    Message.error('数据请求失败');
                }
            }).catch((err) => {
            reject(err);
            Message.error('数据请求失败' + err);
        });
    });
}
