<template>
     <div id="app">
         <transition name="fade" mode="out-in">
             <router-view></router-view>
         </transition>
     </div>
</template>


<script>
export default {
  name: 'App',
    mounted() {
        // window.localStorage.setItem("constant", Date.now().toString());
        // window.addEventListener(
        //     "storage",
        //     function (event) {
        //         if (!event.newValue) {
        //             return;
        //         }
        //         console.log(event.key);
        //         if (event.key === "constant") {
        //             localStorage.setItem(
        //                 "storeSessionData",
        //                 sessionStorage.getItem("access-token")
        //             );
        //             // console.log('sessionStorage.getItem(constant):' + sessionStorage.getItem("userId"))
        //             localStorage.removeItem("storeSessionData");
        //         } else if (event.key === "storeSessionData") {
        //             // console.log('event.newValue:' + event.newValue)
        //             sessionStorage.setItem("access-token", event.newValue);
        //         }
        //     }
        // );
    }
}
</script>

<style>
    *{margin:0;padding:0;}
    html,body,#app,.wrapper{
        width:100%;
        height:100%;
        overflow: hidden;
    }
    body{
        /*font-family:"Helvetica Neue",Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;*/
        font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
        background-color: #F3F5F8;
    }
    a{text-decoration: none}
    .wrap{
        padding: 10px 20px;
    }
    .page-title{
        height: 42px;
        line-height: 42px;
        background-color: #eee;
        border-bottom: 1px solid #d9d9d9;
        padding: 0 10px;
    }
    .page-title h3{
        font-size: 18px;
    }
    .page-title h3 span{
        font-weight: 500;
        font-size: 15px;
        margin-left: 4px;
        cursor: pointer;
    }
</style>
